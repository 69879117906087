// multi card
.multi-card {
  .card-item {
    background-color: $black;
    display: flex;
    margin-bottom: rem-calc(32);
    overflow: hidden;
    position: relative;
    .card-item-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 0!important;
      &.card-item-image-bg {
        img {
          opacity: 0;
        }
      }    
    }
    .card-bottom {  
      color: $black;
      padding: rem-calc(32);
      .card-title-link {
        display: inline-block;
      }
      .card-date {
        font-size: rem-calc(14);
        margin-bottom: rem-calc(8);
      }
      p {
        color: $white;
      }
      .btn {
        max-width: 84%;
      }
    }
    .card-padding-small {
      padding: rem-calc(16);
    }
    .card-padding-medium {
      padding: rem-calc(24);
    }
    .card-padding-large {
      padding: rem-calc(32);
    }
    .card-padding-x-large {
      padding: rem-calc(42);
    }

    // article limits
    &.article-limit-1 {
      @include breakpoint(medium) {
        align-items: center;
        display: flex;
        width: calc(100% - 1.875rem);
        .card-bottom {
          width: 75%;
        }
      }      
    }
    &.article-limit-2 {
      @include breakpoint(large) {
        align-items: center;
        display: flex;
        width: calc(50% - 1.875rem);
        .card-item-image, .card-bottom {
          width: 50%;
        }
      }      
    }
    &.article-limit-4:first-of-type {
      @include breakpoint(medium) {
        align-items: center;
        display: flex;
        width: calc(100% - 1.875rem);
        .card-bottom {
          width: 75%;
        }
      }      
    }
  }

  // card border colors
  &.ele-border-black {
    .card-item {
      border: rem-calc(2) solid $black;
    }
  }
  &.ele-border-dark-grey {
    .card-item {
      border: rem-calc(2) solid $dark-grey;
    } 
  }
  &.ele-border-light-grey {
    .card-item {
      border: rem-calc(2) solid $light-grey;
    } 
  }
  &.ele-border-white {
    .card-item {
      border: rem-calc(2) solid $white;
    }
  }

  // card bg colors
  &.ele-bg-black {
    .card-item {
      background-color: $black;
    }
  }
  &.ele-bg-dark-grey {
    .card-item {
      background-color: $dark-grey;
    } 
  }
  &.ele-bg-light-grey {
    .card-item {
      background-color: $light-grey;
    } 
  }
  &.ele-bg-white {
    .card-item {
      background-color: $white;
    }
  }

  // card font colors
  &.ele-fc-black {
    .card-item {
      .card-title, p {
        color: $black;
      }
    }
  }
  &.ele-fc-dark-grey {
    .card-item {
      .card-title, p {
        color: $dark-grey;
      }
    }
  }
  &.ele-fc-light-grey {
    .card-item {
      .card-title, p {
        color: $light-grey;
      }
    }
  }
  &.ele-fc-white {
    .card-item {
      .card-title, p, p a {
        color: $white;
      }
    }
  }
  
  &.card-fix-buttons {
    .card-item {
      .card-bottom {
        padding-bottom: rem-calc(84);
        &.card-padding-small {
          .btn {
            bottom: rem-calc(16);
          }
        }
        &.card-padding-medium {
          .btn {
            bottom: rem-calc(24);
          }
        }
        &.card-padding-large {
          .btn {
            bottom: rem-calc(32);
          }
        }
        &.card-padding-x-large {
          .btn {
            bottom: rem-calc(42);
          }
        }        
        .btn {
          position: absolute;
        }
      }
    }
  }

  &.text-center {
    .card-item{
      .card-bottom {
        text-align: center;
      }
    }
    &.card-fix-buttons {
      .card-item {
        .card-bottom {
          .btn {
            left:0;
            margin: 0 auto;
            right:0;
          }
        }
      }

    }    
  }
  &.text-left {
    .card-item{
      .card-bottom {
        text-align: left;
      }
    }
    &.card-fix-buttons {
      .card-item {
        .card-bottom {
          &.card-padding-small {
            .btn {
              left: rem-calc(16);
            }
          }
          &.card-padding-medium {
            .btn {
              left: rem-calc(24);
            }
          }
          &.card-padding-large {
            .btn {
              left: rem-calc(32);
            }
          }
          &.card-padding-x-large {
            .btn {
              left: rem-calc(42);
            }
          } 
        }
      }

    }     
  }
  &.text-right {
    .card-item{
      .card-bottom {
        text-align: right;
      }
    }
    &.card-fix-buttons {
      .card-item {
        .card-bottom {
          &.card-padding-small {
            .btn {
              right: rem-calc(16);
            }
          }
          &.card-padding-medium {
            .btn {
              right: rem-calc(24);
            }
          }
          &.card-padding-large {
            .btn {
              right: rem-calc(32);
            }
          }
          &.card-padding-x-large {
            .btn {
              right: rem-calc(42);
            }
          }
        }
      }

    }    
  }

  &.ele-upper {
    .card-item{
      .card-bottom {
        .card-title {
          @extend .uppercase;
        }
      }
    }    
  }
  &.ele-border {
    .card-item{
      border-radius: rem-calc(16);
    }    
  }
  &.ele-shadow {
    .card-item{
      box-shadow: 0 rem-calc(2) rem-calc(10) rgba(0, 0, 0, 0.2);
    }    
  }
}