// buttons
.btn {
	background-color: transparent;
	border: rem-calc(2) solid $white;
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	padding: rem-calc(12) rem-calc(30);
	position: relative;
	text-align: center;
	text-decoration: none;
	transition: all 0.25s ease;
	@include breakpoint(small){
		font-size: rem-calc(19);
		line-height: rem-calc(19);
	}
	@include breakpoint(medium){
		font-size: rem-calc(20);
		line-height: rem-calc(20);
	}	

	&:hover {
		background-color: $white;
		border-color: $white;
		color: $dark-grey;	
		.arrow {
			background-color: $white;
		}	
	}
	&:focus {
		.arrow {
			svg {
				.svg-stroke {
					stroke: $black;
				}
			}
		}	
	}
	&.btn-margin {
		margin-bottom: rem-calc(16);
	}
}

// button style options
.btn-square {
	.btn {		
		border: rem-calc(2) solid $black;
		border-radius: 0;
	}
}

.btn-round {
	.btn {		
		border: rem-calc(2) solid $black;
		border-radius: rem-calc(32);
	}
}

.btn-border {
	.btn {		
		border: rem-calc(2) solid $black;
		color: $black;
	}
}

.btn-border-round {
	.btn {		
		border: rem-calc(2) solid $black;
		border-radius: rem-calc(32);
		color: $black;
	}
}

// button color options
.btn {

	&.btn-arrow {
		padding: rem-calc(8) rem-calc(40) rem-calc(8) rem-calc(12);
		.arrow {
			@include vertical-center();
			background-color: $black;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			-webkit-mask-image: url('/assets/images/icon-arrow-simple.svg');
			mask-image: url('/assets/images/icon-arrow-simple.svg');
			-webkit-mask-repeat: no-repeat;
			mask-repeat: no-repeat;	
			-webkit-mask-size: cover;
			mask-size: cover;				
			height: rem-calc(15);		
			text-align: left;
			transition: all 0.25s ease;
			right: rem-calc(12);
			width: rem-calc(17);
		}
	}

	&.btn-filter {
		padding: rem-calc(12) rem-calc(56) rem-calc(12) rem-calc(16);
	}	

	// transcript button
	&.btn-transcript {
		background-color: $black;
		border-color: $black;
		color: $white;
		.arrow {
			background-color: $white;
		}
		&:hover {
			border-color: $dark-grey;
			background-color: $dark-grey;
			color: $white;
			.arrow {
				background-color: $white;
			}
		}
	}

	// button bg colors
	&.ele-bg-black {
		background-color: $black;
		border-color: $black;
	}
	&.ele-bg-dark-grey {
		background-color: $dark-grey;
		border-color: $dark-grey;
	}
	&.ele-bg-light-grey {
		background-color: $light-grey;
		border-color: $light-grey;
	}
	&.ele-bg-white {
		background-color: $white;
		border-color: $white;
	}
	// button bg hover colors
	&.ele-bg-hv-black {
		&:hover {
			background-color: $black;
			border-color: $black;
		}		
	}
	&.ele-bg-hv-dark-grey {
		&:hover {
			background-color: $dark-grey;	
			border-color: $dark-grey;
		}
	}
	&.ele-bg-hv-light-grey {
		&:hover {
			background-color: $light-grey;
			border-color: $light-grey;
		}
	}
	&.ele-bg-hv-white {
		&:hover {
			background-color: $white;
			border-color: $white;
		}
	}

	// button font colors
	&.ele-fc-black {
		color: $black;
		.arrow {
			background-color: $black;
		}
	}
	&.ele-fc-dark-grey {
		color: $dark-grey;
		.arrow {
			background-color: $dark-grey;
		}
	}
	&.ele-fc-light-grey {
		color: $light-grey;
		.arrow {
			background-color: $light-grey;
		}
	}
	&.ele-fc-white {
		color: $white;
		.arrow {
			background-color: $white;
		}
	}

	// button font hover colors
	&.ele-fc-hv-black {
		&:hover {
			color: $black;
			.arrow {
				background-color: $black;
			}
		}
	}
	&.ele-fc-hv-dark-grey {
		&:hover {
			color: $dark-grey;
			.arrow {
				background-color: $dark-grey;
			}
		}
	}
	&.ele-fc-hv-light-grey {
		&:hover {
			color: $light-grey;
			.arrow {
				background-color: $light-grey;
			}
		}
	}
	&.ele-fc-hv-white {
		&:hover {
			color: $white;
			.arrow {
				background-color: $white;
			}	
		}
	}	

	// arrow types
	&.arrow-round {
		.arrow {
			-webkit-mask-image: url('/assets/images/icon-arrow-round.svg');
			mask-image: url('/assets/images/icon-arrow-round.svg');
			height: rem-calc(26);
			width: rem-calc(26);
		}
	}
	&.arrow-round-solid {
		.arrow {
			-webkit-mask-image: url('/assets/images/icon-arrow-round-solid.svg');
			mask-image: url('/assets/images/icon-arrow-round-solid.svg');
			height: rem-calc(26);
			width: rem-calc(26);
		}
	}
	&.arrow-short {
		padding: rem-calc(12) rem-calc(60) rem-calc(12) rem-calc(30);
		.arrow {
			-webkit-mask-image: url('/assets/images/icon-arrow-short.svg');
			mask-image: url('/assets/images/icon-arrow-short.svg');
			right: rem-calc(16);
		}
	}
	&.arrow-simple {
		.arrow {
			-webkit-mask-image: url('/assets/images/icon-arrow-simple.svg');
			mask-image: url('/assets/images/icon-arrow-simple.svg');
		}
	}

	&:focus {
		.arrow {
			background-color: $black!important;
		}
	}
}



.btn-border, .btn-border-round {
	.btn {
		&.ele-bg-black {
			background-color: transparent;
			border-color: $black;
		}
		&.ele-bg-dark-grey {
			background-color: transparent;
			border-color: $dark-grey;
		}
		&.ele-bg-light-grey {
			background-color: transparent;
			border-color: $light-grey;
		}
		&.ele-bg-white {
			background-color: transparent;
			border-color: $white;
		}
		// button bg hover
		&.ele-bg-hv-black {
			&:hover {
				background-color: $black;
				border-color: $black;
			}
		}
		&.ele-bg-hv-dark-grey {
			&:hover {
				background-color: $dark-grey;
				border-color: $dark-grey;
			}
		}
		&.ele-bg-hv-light-grey {
			&:hover {
				background-color: $light-grey;
				border-color: $light-grey;
			}
		}
		&.ele-bg-hv-white {
			&:hover {
				background-color: $white;
				border-color: $white;
			}
		}

		&.ele-fc-black {
			color: $black;
			.arrow {
				background-color: $black;
			}
		}
		&.ele-fc-dark-grey {
			color: $dark-grey;
			.arrow {
				background-color: $dark-grey;
			}
		}
		&.ele-fc-light-grey {
			color: $light-grey;
			.arrow {
				background-color: $light-grey;
			}
		}
		&.ele-fc-white {
			color: $white;
			.arrow {
				background-color: $white;
			}
		}
		// button font hover
		&.ele-fc-hv-black {
			&:hover {
				color: $black;
				.arrow {
					background-color: $black;
				}
			}
		}
		&.ele-fc-hv-dark-grey {
			&:hover {
				color: $dark-grey;
				.arrow {
					background-color: $dark-grey;
				}
			}
		}
		&.ele-fc-hv-light-grey {
			&:hover {
				color: $light-grey;
				.arrow {
					background-color: $light-grey;
				}
			}
		}
		&.ele-fc-hv-white {
			&:hover {
				color: $white;
				.arrow {
					background-color: $white;
				}
			}
		}		
		&.btn-transcript {
			background-color: transparent;
			border-color: $black;
			color: $black;
			.arrow {
				background-color: $black;
			}
			&:hover {
				border-color: $dark-grey;
				background-color: $dark-grey;
				color: $white;
				.arrow {
					background-color: $white;
				}
			}
		}
		// button focus
		&:focus {
			.arrow {
				background-color: $black!important;
			}
		}
	}

}

.btn-close-modal {
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;	
	max-width: rem-calc(370);
	padding: rem-calc(20) rem-calc(72) rem-calc(20) rem-calc(20);
	position: relative;
	text-align: left;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 0.25s ease;
	@include breakpoint(small){
		font-size: rem-calc(16);
		line-height: rem-calc(16);
	}
	@include breakpoint(medium){
		font-size: rem-calc(16);
		line-height: rem-calc(17);
	}	
	&:after {
		@include vertical-center();
		background-image: url('/assets/images/icon-close-white.svg');
		background-position: center;
		background-repeat: no-repeat;	
		background-size: contain;	
		content:'';
		display: block;
		height: rem-calc(26);
		transition: all 0.25s ease;
		right: rem-calc(24);
		width: rem-calc(23);
	}
	&:focus {
		&:after {
			background-image: url('/assets/images/icon-close-black.svg');
		}	
	}
}

.btn-play {
	// background-color: $black;
	// background-position: center;
	// background-repeat: no-repeat;
	// background-size: contain;
	// -webkit-mask-image: url('/assets/images/icon-play-simple.svg');
	// mask-image: url('/assets/images/icon-play-simple.svg');
	position: relative;	
	cursor: pointer;
	transition: all 0.25s ease;
	&:hover {
		opacity: 0.8;
	}

	&:focus {
		.btn-play-inner {
			fill: $focus-color!important;
		}	
	}
	@include breakpoint(small){
		height: rem-calc(70);
		width: rem-calc(70);
	}
	@include breakpoint(medium){
		height: rem-calc(93);
		width: rem-calc(93);
	}
}

.play-style-border {
	.btn-play {
		-webkit-mask-image: url('/assets/images/icon-play-border.svg');
		mask-image: url('/assets/images/icon-play-border.svg');	
		&:focus {
			background-image: url('/assets/images/icon-play-border.svg');	
			-webkit-mask-image: none;
			mask-image: none;	
		}
	}
}
.play-style-simple {
	.btn-play {
		-webkit-mask-image: url('/assets/images/icon-play-simple.svg');
		mask-image: url('/assets/images/icon-play-simple.svg');	
		&:focus {
			background-image: url('/assets/images/icon-play-simple.svg');
			-webkit-mask-image: none;
			mask-image: none;		
		}
	}
}
.play-style-solid {
	.btn-play {
		-webkit-mask-image: url('/assets/images/icon-play-solid.svg');
		mask-image: url('/assets/images/icon-play-solid.svg');	
		&:focus {
			background-image: url('/assets/images/icon-play-solid.svg');
			-webkit-mask-image: none;
			mask-image: none;		
		}
	}
}
.play-style-square {
	.btn-play {
		-webkit-mask-image: url('/assets/images/icon-play-square.svg');
		mask-image: url('/assets/images/icon-play-square.svg');	
		&:focus {
			background-image: url('/assets/images/icon-play-square.svg');
			-webkit-mask-image: none;
			mask-image: none;		
		}
	}
}

.play-c-black {
	.btn-play {
		background-color: $black;
	}
}
.play-c-dark-grey {
	.btn-play {
		background-color: $dark-grey;
	}
}
.play-c-light-grey {
	.btn-play {
		background-color: $light-grey;
	}
}
.play-c-white {
	.btn-play {
		background-color: $white;
	}
}

// button override
.btn-all-bold {
	.btn {
		font-weight: bold;
	}
	.header{
		&.nav-link-btn {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									font-weight: bold;
								}
							}
						}
					}
				}
			}
		}
	}
}
.btn-all-upper {
	.btn {
		text-transform: uppercase;
	}
	.header{
		&.nav-link-btn {
			.nav-menu-container {
				.nav {
					> ul {
						> li {
							&:last-of-type {
								a {
									text-transform: uppercase;
								}
							}
						}
					}
				}
			}
		}
	}
}