// typography
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

.light {
  font-weight: 300 !important;
}
.regular {
  font-weight: 400 !important;
}
.medium {
  font-weight: 500 !important;
}
.bold {
  font-weight: 700 !important;
}
.underline {
  text-decoration: underline;
}
.uppercase {
  text-transform: uppercase;
}

ul {
  &.list-dash {
    list-style: none;
    margin-left: rem-calc(24);
    li {
      position: relative;
      margin-bottom: rem-calc(8);
      &:before {
        @include vertical-center;
        content: "";
        display: block;
        left: rem-calc(-24);
        width: rem-calc(8);
        border-top: rem-calc(1) solid $black;
      }
      a,
      p {
        text-decoration: underline;
      }
      p {
        margin: 0;
        text-decoration: none;
      }
    }
  }
}

p,
li {
  color: $black;
  @include breakpoint(small) {
    font-size: rem-calc(16);
    line-height: rem-calc(20);
  }
  @include breakpoint(large) {
    font-size: rem-calc(18);
    line-height: rem-calc(24);
  }
  a {
    text-decoration: underline;
  }
}

.section-title-x-large {
  @include breakpoint(small) {
    font-size: rem-calc(30);
    line-height: rem-calc(34);
  }
  @include breakpoint(large) {
    font-size: rem-calc(46);
    line-height: rem-calc(46);
  }
}
.section-title-large {
  @include breakpoint(small) {
    font-size: rem-calc(32);
    line-height: rem-calc(38);
  }
  @include breakpoint(large) {
    font-size: rem-calc(38);
    line-height: rem-calc(46);
  }
}
.section-title-medium {
  @include breakpoint(small) {
    font-size: rem-calc(24);
    line-height: rem-calc(32);
  }
  @include breakpoint(large) {
    font-size: rem-calc(30);
    line-height: rem-calc(36);
  }
}
.section-title-small {
  @include breakpoint(small) {
    font-size: rem-calc(18);
    line-height: rem-calc(24);
  }
  @include breakpoint(large) {
    font-size: rem-calc(20);
    line-height: rem-calc(28);
  }
}
.section-title-x-small {
  @include breakpoint(small) {
    font-size: rem-calc(16);
    line-height: rem-calc(18);
  }
  @include breakpoint(large) {
    font-size: rem-calc(18);
    line-height: rem-calc(24);
  }
}
