// svg heading
.svg-heading {  
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: rem-calc(32);
  @include breakpoint(large){
    margin-bottom: 0;
    margin-left: initial;
    margin-right: initial;
  }
  .svg-heading-title {    
    font-weight: bold;
    left: rem-calc(24);
    margin: 0;
    position: absolute;
    top: 48%;    
    transform: translate(0, -50%);    
    width: calc(94% - 1.5rem);
  }
}