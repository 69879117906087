// hero
.hero-banner  {
  position: relative;
  overflow: hidden;

  .hero-container {   
    height: 100%;
    max-width: $global-width;
    margin: 0 auto;
    padding: rem-calc(15);
    width: 100%;
    z-index: 2;
    .hero-content {
      align-content: center;
      display: flex;
      height: 100%;
      width: 100%;
      &.hero-column {
        flex-direction: column;
      }
      &.hero-top-left {
        align-items: flex-start;
        justify-content: flex-start;        
      }
      &.hero-top-right {
        align-items: flex-start;
        justify-content: flex-end;        
      }
      &.hero-top-center {
        align-items: flex-start;
        justify-content: center;        
      }
      &.hero-bottom-left {
        align-items: flex-end;
        justify-content: flex-start;        
      }
      &.hero-bottom-right {
        align-items: flex-end;
        justify-content: flex-end;        
      }
      &.hero-bottom-center {
        align-items: flex-end;
        justify-content: center;        
      }
      &.hero-center-left {
        align-items: center;
        justify-content: flex-start;        
      }
      &.hero-center-right {
        align-items: center;
        justify-content: flex-end;        
      }
      &.hero-center-center {
        align-items: center;
        justify-content: center;        
      }
      .hero-content-inner {
        &.hero-title-padding-small {
          padding: rem-calc(16);
        }
        &.hero-title-padding-medium {
          padding: rem-calc(24);
        }
        &.hero-title-padding-large {
          padding: rem-calc(32);
        }
        &.hero-title-padding-x-large {
          padding: rem-calc(42);
        }
      }
      .hero-title {
        display: inline-block;
        margin: 0;
        &.ele-margin {
          margin-bottom: rem-calc(16);
        }
      }
      .hero-text {
        &.fc-black {
          h1, h2, h3, h4, h5, h6, p, li {
            color: $black;
          }
        }
        &.fc-dark-grey {
          h1, h2, h3, h4, h5, h6, p, li {
            color: $dark-grey;
          }
        }
        &.fc-light-grey {
          h1, h2, h3, h4, h5, h6, p, li {
            color: $light-grey;
          }
        }
        &.fc-white {
          h1, h2, h3, h4, h5, h6, p, li {
            color: $white;
          }
        }

      }
    }
  }
  .hero-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .hero-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);
  }
  .hero-banner-pause {
    background-color: $black;
    bottom: rem-calc(32);  
    position: absolute;
    right: rem-calc(32);
    width: 100%;
    z-index: 3;
    &:hover {
      background-color: $white;
    }
    @include breakpoint(small) {
      height: rem-calc(50);
      max-width: rem-calc(50);
      padding: 0;                   
    }
    @include breakpoint(large) {
      height: auto;
      max-width: rem-calc(132);
      padding: rem-calc(12) rem-calc(30) rem-calc(12) rem-calc(50);        
    }
    .video-icon {     
      @include vertical-center;
      background-color: $white;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;      
      transition: all 0.25s ease;
      @include breakpoint(small) {
        height: rem-calc(32);
        left: 0;
        margin: 0 auto;
        right: 0;
        width: rem-calc(32);            
      }
      @include breakpoint(large) {
        height: rem-calc(32);
        left: rem-calc(16);
        right: auto;
        width: rem-calc(32);            
      }
    }
    .video-text {
      color: $white;
      @include breakpoint(small) {
        display: none;           
      }
      @include breakpoint(large) {
        display: block;            
      }
    }
    &.video-play {
      .video-icon {      
        background-color: $white;  
        -webkit-mask-image: url('/assets/images/icon-play.svg');
        mask-image: url('/assets/images/icon-play.svg');
      }
    }
    &.video-pause {
      .video-icon {
        background-color: $white;
        -webkit-mask-image: url('/assets/images/icon-pause.svg');
        mask-image: url('/assets/images/icon-pause.svg');
      }
    }
    &:hover, &:focus {
      .video-icon {
        background-color: $black;
      }
      .video-text {
        color: $black;
      }
    }
  }
  &.hero-banner-full {
    min-height: 100vh;
  }
  &.hero-banner-default {
    .hero-container {
      position: relative;
      @include breakpoint(small){
        padding: rem-calc(128) rem-calc(10);
      }
      @include breakpoint(medium){
        padding: rem-calc(160) rem-calc(15);
      }
    }
  }
  &.hero-banner-absolute {
    @include breakpoint(small){
      min-height: 50vh;
    }
    @include breakpoint(large){
      min-height: auto;
    }
    .hero-container {
      @include vertical-center;    
      height: 100%;
      max-width: $global-width;
      margin: 0 auto;
      left: 0;
      padding: rem-calc(15);
      right: 0;
      width: 100%;
      z-index: 2;
      .grid-x {
        height: 100%;
      }
    }
    &.hero-svg {
      min-height: auto;
    }
  }
  &.hero-parallax {
    .hero-image {
      background-attachment: fixed;
    }
  }
  .svg-content-container {
    margin: 0 auto;
    max-width: rem-calc(1240);
    position: relative;
    img {
      max-height: rem-calc(575);
      @include breakpoint(medium){
        padding-left: rem-calc(180);
      }
      @include breakpoint(large){
        padding-left: rem-calc(240);
      }
    }
  }
  .svg-title {
    @include breakpoint(small){
      margin: 0 auto;
      max-width: rem-calc(200);
      position: relative;
    }
    @include breakpoint(medium){
      left:0;
      margin: auto;      
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    @include breakpoint(large){
      top: 40%;
    }
    h1 {
      font-weight: bold;
      margin: 0;
      position: absolute;
      top: 50%;      
      transform: translate(0, -50%);      
      width: calc(94% - 1rem);
      @include breakpoint(small){
        left: rem-calc(16);
      }
    }

  }
}

.hero-banner-large {
  .hero-banner {
    .svg-content-container {
      img {
        @include breakpoint(medium){
          padding-left: rem-calc(232);
        }
        @include breakpoint(large){
          padding-left: rem-calc(300);
        }
      }
    }
  }
}