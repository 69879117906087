.footer {
  background-color: $black;
  position: relative;
  text-align: left;
  .grid-relative {
    z-index: 1;
    .footer-content {
      align-content: center;
      align-items: center;      
      &.footer-aligned {        
        @include breakpoint(small) {
          justify-content: center;
          text-align: center;
        }
        @include breakpoint(large) {
          justify-content: space-between;
          text-align: initial;
          .footer-left, .footer-centre, .footer-right {
            width: auto;
          }
        }
        .footer-left {
          display: flex;
          justify-content: space-between;          
          width: 100%;
          @include breakpoint(small) {
            max-width: rem-calc(320);
          }
          @include breakpoint(medium) {
            max-width: rem-calc(480);
          }
          @include breakpoint(large) {
            max-width: rem-calc(320);
          }

        }
      }
      &.footer-stacked {
        justify-content: center;
        text-align: center;
        .footer-text {
          text-align: center;
        }
      }      
    }
  }

  // footer logos
  .footer-left-logo {
    @include breakpoint(small) {
      text-align: center;
    }
    img {
      max-width: rem-calc(170);
      @include breakpoint(small) {
        margin-bottom: rem-calc(20);
      }
      @include breakpoint(large) {
        margin-bottom: 0;
      }
    }

  }
  .footer-centre-logo {
    img {
      margin: rem-calc(16) 0 rem-calc(48) 0;
      @include breakpoint(large){
        margin: 0;
      } 
      max-width: rem-calc(170);
    }
  }
  .footer-right-logo {
    img {
      max-height: rem-calc(80);
    }
  }


  // footer links
  .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    text-align: center;
    @include breakpoint(small) {
      flex-direction: column;
      margin: 0 0 rem-calc(8) 0;
    }
    li {
      display: block;
      @include breakpoint(small) {
        margin: rem-calc(4) 0;
      }
      @include breakpoint(medium) {
        margin: 0;
      }
      a {
        color: $white;
        display: inline-block;
        font-size: rem-calc(12);
        line-height: rem-calc(16);
        margin: 0;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  // footer text
  .footer-text {
    margin: 0 0 rem-calc(8) 0;
    p {
      margin: 0;
    }
  }

	// footer link spacing
	&.footer-f-space-sm {
    .footer-links {
      li {
        a {
          @include breakpoint(large){
            margin: 0 rem-calc(10);							
          }	
        }
      }					
    }
	}
	&.footer-f-space-md {
    .footer-links {
      li {
        a {
          @include breakpoint(large){
            margin: 0;							
          }	          
        }
      }						
    }
	}
	&.footer-f-space-lg {
    .footer-links {
      li {
        a {
          @include breakpoint(large){
            margin: 0 rem-calc(24);							
          }	          
        }
      }						
    }
	}
	&.footer-f-space-xl {
    .footer-links {
      li {
        a {
          @include breakpoint(large){
            margin: 0 rem-calc(32);							
          }		         
        }
      }						
    }
	}
  // footer links align
  .f-text-left {
    @include breakpoint(large){
      text-align: left;
      .footer-social, .footer-links, .footer-text {
        text-align: left;
      }
      .footer-social {
        margin-right: auto;
        .social-links {
          justify-content: flex-start; 
        } 
      }
    }   
  }
  .f-text-center {
    @include breakpoint(large){
      text-align: center;
      .footer-social, .footer-links, .footer-text {
        text-align: center;
      }
      .footer-social {
        .social-links {
          justify-content: center; 
        } 
      }
    } 
  }
  .f-text-right {
    @include breakpoint(large){
      text-align: right;
      .footer-social, .footer-links, .footer-text {
        text-align: right;
      }
      .footer-social {
        margin-right: auto;
        .social-links {
          justify-content: flex-end; 
        } 
      }
    } 
  }

  // footer social / legal
  .footer-social-legal {
    text-align: center;
    @include breakpoint(large) {
      max-width: rem-calc(400);
    }
  }

  // social links
  .footer-social {
    margin: rem-calc(20) 0;
    text-align: center;
    .social-title {
      margin-bottom: rem-calc(20);
      @include breakpoint(small) {
        font-size: rem-calc(20);
        line-height: rem-calc(20);
      }
      @include breakpoint(medium) {
        font-size: rem-calc(24);
        line-height: rem-calc(24);
      }    
    }
    .social-links {
      display: flex;
      flex-wrap: wrap;  
      justify-content: center;  
      list-style: none;
      margin: 0 auto;    
      li {
        .social-icon {
          align-items: center;
          display: flex;
          height: rem-calc(38);
          margin-left: rem-calc(16);
          padding-left: rem-calc(48);
          position: relative;
          text-decoration: none;
          &:before {
            background-color: $black;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content:'';
            display: block;
            left: 0;
            margin: 0;
            -webkit-mask-position: center;
            mask-position: center;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: contain;
            mask-size: contain;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            @include breakpoint(small) {
              height: rem-calc(32);
              width: rem-calc(32);            
            }
            @include breakpoint(large) {
              height: rem-calc(38);
              width: rem-calc(38);            
            }
          }
          &.facebook {
            &:before{
              background-color: $social-facebook;
              -webkit-mask-image: url('/assets/images/social-facebook.svg');
              mask-image: url('/assets/images/social-facebook.svg');
            }
            &:focus {
              &:before{
                background-image: url('/assets/images/social-facebook.svg');              
              }
            }
          }
          &.instagram {
            &:before{
              background-color: $social-instagram;
              -webkit-mask-image: url('/assets/images/social-instagram.svg');
              mask-image: url('/assets/images/social-instagram.svg');              
            }
            &:focus {
              &:before{
                background-image: url('/assets/images/social-instagram.svg');              
              }
            }
          }
          &.linkedin {
            &:before{
              background-color: $social-linkedin;
              -webkit-mask-image: url('/assets/images/social-linkedin.svg');
              mask-image: url('/assets/images/social-linkedin.svg');              
            }
          }
          &.tiktok {
            &:before{
              background-color: $social-tiktok;
              -webkit-mask-image: url('/assets/images/social-tiktok.svg');
              mask-image: url('/assets/images/social-tiktok.svg');              
            }
            &:focus {
              &:before{
                background-image: url('/assets/images/social-tiktok.svg');              
              }
            }
          }
          &.twitter {
            &:before{
              background-color: $social-twitter;
              -webkit-mask-image: url('/assets/images/social-twitter.svg');
              mask-image: url('/assets/images/social-twitter.svg');              
            }
          }
          &.vimeo {
            &:before{
              background-color: $social-vimeo;
              -webkit-mask-image: url('/assets/images/social-vimeo.svg');
              mask-image: url('/assets/images/social-vimeo.svg');              
            }
            &:focus {
              &:before{
                background-image: url('/assets/images/social-vimeo.svg');              
              }
            }
          }
          &.youtube {
            &:before{
              background-color: $social-youtube;
              -webkit-mask-image: url('/assets/images/social-youtube.svg');
              mask-image: url('/assets/images/social-youtube.svg');              
            }
            &:focus {
              &:before{
                background-image: url('/assets/images/social-youtube.svg');              
              }
            }
          }          

          &:hover {
            opacity: 0.9;
            text-decoration: underline;
          }
          &:focus {
            -webkit-mask-image: none;
            mask-image: none;
            &:before {
              background-color: $black!important;
            }
            .social-link-name {
              color: $black!important;
            }
          }
        }
        &:first-of-type {
          .social-icon {
            margin-left: 0;
          }
        }
        &:last-of-type {
          .social-icon {
            margin-right: 0;
          }
        }
      }
    }
    &.social-size-large {
      .social-links {
        li {
          .social-icon {
            @include breakpoint(small) {
              height: rem-calc(40);
              width: rem-calc(40);            
            }
            @include breakpoint(large) {
              height: rem-calc(64);
              width: rem-calc(64);            
            }
          }
        }
      }
    }
    &.social-size-medium {
      .social-links {
        li {
          .social-icon {
            &:before {
              @include breakpoint(small) {
                height: rem-calc(32);
                width: rem-calc(32);            
              }
              @include breakpoint(large) {
                height: rem-calc(48);
                width: rem-calc(48);            
              }
            }
          }
        }
      }
    }
    &.social-size-small {
      .social-links {
        li {
          .social-icon {
            @include breakpoint(small) {
              height: rem-calc(24);
              width: rem-calc(24);            
            }
            @include breakpoint(large) {
              height: rem-calc(32);
              width: rem-calc(32);            
            }
          }
        }
      }
    }
    &.social-size-tiny {
      .social-links {
        li {
          .social-icon {
            font-size: rem-calc(12);
            line-height: rem-calc(12);
            &:before {
              height: rem-calc(40);
              width: rem-calc(40);
            }


          }
        }
      }
    }
    // social icon colour
    &.social-col-black {
      .social-links {
        li {
          .social-icon {
            background-color: $black;
          }
        }
      }
    }
    &.social-col-dark-grey {
      .social-links {
        li {
          .social-icon {
            background-color: $dark-grey;
          }
        }
      }
    }
    &.social-col-light-grey {
      .social-links {
        li {
          .social-icon {
            background-color: $light-grey;
          }
        }
      }
    }
    &.social-col-white {
      .social-links {
        li {
          .social-icon {
            &:before {
              background-color: $white;
            }            
          }
        }
      }
    }
  }

  // background colour
  &.ele-bg-black {
		background-color: $black;
	}
	&.ele-bg-dark-grey {
		background-color: $dark-grey;
	}
	&.ele-bg-light-grey {
		background-color: $light-grey;
	}
	&.ele-bg-white {
		background-color: $white;
	} 
  
  // font colour
  &.ele-fc-black {
    color: $black;
    p {
      color: $black;
    }
    .footer-links {
      li {
        a {
          color: $black;
        }
      }
    }
    .footer-social {
      .social-title {
        color: $black;
      }
    }
  }
  &.ele-fc-dark-grey {
    color: $dark-grey;
    p {
      color: $dark-grey;
    }
    .footer-links {
      li {
        a {
          color: $dark-grey;
        }
      }
    }
    .footer-social {
      .social-title {
        color: $dark-grey;
      }
    }
  }
  &.ele-fc-light-grey {
    color: $light-grey;
    p {
      color: $light-grey;
    }
    .footer-links {
      li {
        a {
          color: $light-grey;
        }
      }
    }
    .footer-social {
      .social-title {
        color: $light-grey;
      }
    }
  }
  &.ele-fc-white {
    color: $white;
    p {
      color: $white;
    }
    .footer-links {
      li {
        a {
          color: $white;
        }
      }
    }
    .footer-social {
      .social-title, .social-link-name {
        color: $white;
      }
    }
  } 

  // footer links type
  &.footer-nav-row {
    .footer-links {
      @include breakpoint(small) {
        flex-direction: column;
      }
      @include breakpoint(medium) {
        flex-direction: row;
      }
    }
  }
  &.footer-nav-col {
    .footer-links {
      @include breakpoint(small) {
        flex-direction: column;
      }
      @include breakpoint(medium) {
        flex-direction: column;
        li {
          line-height: rem-calc(20);
          a {
            margin: 0;
          }
        }
      }
    }
  }

  // footer vertical align
  &.f-v-align-top {
    .footer-content {
      @include breakpoint(large){
        align-items: flex-start;
      }      
    }
  }
  &.f-v-align-middle {
    .footer-content {
      margin: 0 auto;
      max-width: rem-calc(1000);
      @include breakpoint(large){
        align-items: center;
      }      
    }
  }
  &.f-v-align-bottom {
    .footer-content {
      @include breakpoint(large){
        align-items: flex-end;
      }      
    }
  }

	// footer links font size
	&.f-f-size-sm {
		.footer-links {
      li {
        a {
          @include breakpoint(large){
            font-size: rem-calc(12);					
          }														
        }
      }
		}
    .footer-text {
      p {
        @include breakpoint(large){
          font-size: rem-calc(16);					
        }	
      }
    }
	}
	&.f-f-size-md {
		.footer-links {
      li {
        a {
          @include breakpoint(large){
            font-size: rem-calc(14);					
          }														
        }
      }
		}  
    .footer-text {
      p {
        @include breakpoint(large){
          font-size: rem-calc(14);					
        }	
      }
    }
	}
	&.f-f-size-lg {
		.footer-links {
      li {
        a {
          @include breakpoint(large){
            font-size: rem-calc(16);					
          }														
        }
      }
		}   
    .footer-text {
      p {
        @include breakpoint(large){
          font-size: rem-calc(16);					
        }	
      }
    }   
	}
	&.f-f-size-xl {
		.footer-links {
      li {
        a {
          @include breakpoint(large){
            font-size: rem-calc(18);					
          }														
        }
      }
		} 
    .footer-text {
      p {
        @include breakpoint(large){
          font-size: rem-calc(18);					
        }	
      }
    }
	}  
}
