// video modal
.modal-video {
  // video colors
  &.video-fc-black {
    .video-item {
      color: $black;
    }
  }
  &.video-fc-white {
    .video-item {
      color: $white;
    }
  }
  &.video-fc-dark-grey {
    .video-item {
      color: $dark-grey;
    }
  }
  &.video-fc-light-grey {
    .video-item {
      color: $light-grey;
    }
  }
  //overwrite
  &.video-fc-primary {
    .video-item {
      color: $primary-color;
    }
  }

  &.video-center {
    .video-item {
      text-align: center;
    }
  }
  
  &.video-left {
    .video-item {
      text-align: left;
    }
  }
  
  &.video-right {
    .video-item {
      text-align: right;
    }
  }
}
.video-item {
  .video-poster {
    background-color: $black;
    position: relative;
    .video-title {
      color: $white;
      font-size: rem-calc(24);
      font-weight: bold;
      left: rem-calc(48);
      line-height: rem-calc(30);
      max-width: rem-calc(176);
      position: absolute;
      top: rem-calc(48);
    }
    .video-content {
      position: relative;
    }
    .btn-play {
      @include vertical-center;
      height: rem-calc(64);
      left: 0;
      margin: 0 auto;
      right:0;
      width: rem-calc(64);
      &:focus {
        .btn-play-fill {
          fill: $black;
        }
        .btn-play-stroke {
          stroke: $black;
        }
      }
    }
  }
}

// modal two per row
.modal-button-small {
  .video-item {
    .video-poster {
      .video-title {
        font-size: rem-calc(20);
        font-weight: bold;
        left: rem-calc(32);
        line-height: rem-calc(24);
        max-width: rem-calc(176);
        top: rem-calc(32);
      }
      .btn-play {
        bottom: rem-calc(32);
        height: rem-calc(77);
        left: rem-calc(32);
        margin: 0;
        right: inherit;
        top: inherit;
        transform: none;
        width: rem-calc(77);
      }
    }
    &.video-item-portrait {
      .video-poster {
        .video-title {
          top: rem-calc(64);
        }
      }
    }
  }
}

.video-item-portrait {
  .video-poster {
    .video-poster-bottom {
      bottom: 0;
      height: rem-calc(146);
      left: 0;
      position: absolute;
      width: 100%;
      .video-poster-bottom-bg-white, .video-poster-bottom-bg-main {        
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
      }
      .video-poster-bottom-bg-white {
        background-color: $white;       
        opacity: 0.83; 
      }
      .video-poster-bottom-bg-main {
        background-color: $black;
        mix-blend-mode: multiply;
      }
      .video-title {
        font-size: rem-calc(18);
        font-weight: bold;
        left: rem-calc(102);
        line-height: rem-calc(24);
        max-width: rem-calc(256); 
        right: rem-calc(16);     
        top: 50%;
        transform: translateY(-50%);
      }
      .btn-play {
        height: rem-calc(64);
        left: rem-calc(20);
        margin: 0;
        right: inherit;
        top: 50%;
        transform: translateY(-50%);
        width: rem-calc(64);
      }
    }

  }
}


// video with limit
.video-list-limit {
  .video-hidden {
    display: none;
  }
}
.limitViewMoreBtn {
  cursor: pointer;
  display: inline-block;
  font-size: rem-calc(20);
  font-weight: bold;
  padding-bottom: rem-calc(28);
  position: relative;
  text-decoration: underline;
  &:before {
    background-image: url('/assets/images/arrow-down.svg');	
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    content: "";
    display: block;
    height: rem-calc(18);
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: rem-calc(35);
  }
}


// responsive-embed
.responsive-embed {
  margin-bottom: 0;
  padding-bottom: 58%;
  iframe {
    border: 0;
  }
  &.portrait {
    padding-bottom: 177.78%;
  }
}

// html embed
.html-embed {
  max-width: 100%;
  iframe {
    border: 0;
    max-width: 100%;
  }
}
