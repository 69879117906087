// multi image
.multi-image {
  &.ele-margin {
    .cell {
      img {
        margin-bottom: rem-calc(16);
      }
    }
  }
}
.multi-image-full {
  img {
    width: 100%;
  }
}

.card-img-mobile {
  @include breakpoint(small){
    display: block;
    height: rem-calc(320);
  }
  @include breakpoint(medium){
    height: rem-calc(480);
  }
  @include breakpoint(large){
    display: none;    
  }
}