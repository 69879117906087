// tabs
.tab-container {
  .tabs {
    border-bottom: rem-calc(2) solid $charcol;
    justify-content: space-evenly;
    list-style-type: none;
    margin: 0;
    @include breakpoint(small) {
      display: none;
    }
    @include breakpoint(large) {
      display: flex;
    }
    &:before,
    &:after {
      display: table;
      content: " ";
      flex-basis: 0;
      order: 1;
    }

    .tabs-title {
      &:first-of-type {
        a {
          margin-left: 0;
        }
      }
      &:last-of-type {
        a {
          margin-right: 0;
        }
      }
      position: relative;
      text-align: center;
      width: 100%;
      a {
        background-color: $white;
        border: rem-calc(2) solid $charcol;
        color: $charcol;
        display: block;
        font-size: rem-calc(24);
        font-weight: 500;
        line-height: rem-calc(32);
        margin: 0 5% rem-calc(-3) 5%;
        padding: rem-calc(10) rem-calc(20);
        text-decoration: none;

        &:focus {
          border: rem-calc(2) solid $black;
          box-shadow: none !important;
        }
        
        // tab font size
        &.ele-fs-large {
          @include breakpoint(small) {
            font-size: rem-calc(20);
            line-height: rem-calc(20);
          }
          @include breakpoint(medium) {
            font-size: rem-calc(24);
            line-height: rem-calc(24);
          }
        }
        &.ele-fs-medium {
          @include breakpoint(small) {
            font-size: rem-calc(18);
            line-height: rem-calc(18);
          }
          @include breakpoint(medium) {
            font-size: rem-calc(20);
            line-height: rem-calc(20);
          }
        }
        &.ele-fs-small {
          @include breakpoint(small) {
            font-size: rem-calc(16);
            line-height: rem-calc(16);
          }
          @include breakpoint(medium) {
            font-size: rem-calc(18);
            line-height: rem-calc(18);
          }
        }
        &.ele-fs-x-small {
          @include breakpoint(small) {
            font-size: rem-calc(16);
            line-height: rem-calc(16);
          }
        }
        &.ele-fs-xx-small {
          @include breakpoint(small) {
            font-size: rem-calc(14);
            line-height: rem-calc(14);
          }
        }
      }
      &.is-active {
        a {
          background-color: $charcol;
          color: $white;
          &:focus {
            border: rem-calc(2) solid $black;
            box-shadow: none !important;
          }
        }
      }
    }
  }

  .tabs-content {
    background: $white;
    border: rem-calc(2) solid $charcol;
    border-top: 0;
    position: relative;    
    .tabs-panel {
      overflow: hidden;
      padding: 0;
      position: relative;
      .tab-img {
        max-width: rem-calc(200);
        padding: rem-calc(32);
      }
      .tab-bg-img {
        .tab-img {
          opacity: 0;
          padding: 0;
        }
      }
      .bg-img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 50%;
      }
      @include breakpoint(large) {
        display: none;
        min-height: rem-calc(250);
      }
      .tab-accordion-title {
        background: $white;
        border: rem-calc(2) solid $black;
        color: $charcol;
        cursor: pointer;
        font-size: rem-calc(20);
        font-weight: bold;
        padding: rem-calc(10);
        position: relative;
        width: 100%;
        z-index: 1;
        &.is-active {
          background: $charcol;
          color: $white;
        }
        @include breakpoint(small) {
          display: block;
        }
        @include breakpoint(large) {
          display: none;
        }
      }

      .tab-main-content {
        @include breakpoint(small) {
          display: none;
        }
        @include breakpoint(large) {
          display: block;
        }
        .content-block {
          padding: rem-calc(48) rem-calc(32);
          p {
            a {
              text-decoration: underline;
            }
          }
          img {
            max-width: rem-calc(160);
          }
        }
      }

      &.is-active {
        display: block;
        .tab-main-content {
          display: block;
        }
      }
      .icon {
        margin-right: rem-calc(20);
      }
    }
  }
  &.ele-shadow {
    .tabs-content {
      box-shadow: 0 rem-calc(2) rem-calc(10) rgba(0, 0, 0, 0.2);
    }
  }
  // tab bg color
  &.ele-bg-black {
    .tabs {
      .tabs-title {
        &.is-active {
          a {
            background-color: $black;
          }
        }
      }
    }
    .tabs-content {
      .tab-accordion-title {
        &.is-active {
          background-color: $black;
        }
      }
    }
  }
  &.ele-bg-white {
    .tabs {
      .tabs-title {
        &.is-active {
          a {
            background-color: $white;
          }
        }
      }
    }
    .tabs-content {
      .tab-accordion-title {
        &.is-active {
          background-color: $white;
        }
      }
    }
  }
  &.ele-bg-dark-grey {
    .tabs {
      .tabs-title {
        &.is-active {
          a {
            background-color: $dark-grey;
          }
        }
      }
    }
    .tabs-content {
      .tab-accordion-title {
        &.is-active {
          background-color: $dark-grey;
        }
      }
    }
  }
  &.ele-bg-light-grey {
    .tabs {
      .tabs-title {
        &.is-active {
          a {
            background-color: $light-grey;
          }
        }
      }
    }
    .tabs-content {
      .tab-accordion-title {
        &.is-active {
          background-color: $light-grey;
        }
      }
    }
  }

  // tab font color
  &.ele-fc-black {
    .tabs {
      .tabs-title {
        &.is-active {
          a {
            color: $black;
          }
        }
      }
    }
    .tabs-content {
      .tabs-panel {
        .tab-accordion-title {
          &.is-active {
            color: $black;
          }
        }
      }
    }
  }

  &.ele-fc-dark-grey {
    .tabs {
      .tabs-title {
        &.is-active {
          a {
            color: $dark-grey;
          }
        }
      }
    }
    .tabs-content {
      .tabs-panel {
        .tab-accordion-title {
          &.is-active {
            color: $dark-grey;
          }
        }
      }
    }
  }
  &.ele-fc-light-grey {
    .tabs {
      .tabs-title {
        &.is-active {
          a {
            color: $light-grey;
          }
        }
      }
    }
    .tabs-content {
      .tabs-panel {
        .tab-accordion-title {
          &.is-active {
            color: $light-grey;
          }
        }
      }
    }
  }
  &.ele-fc-white {
    .tabs {
      .tabs-title {
        &.is-active {
          a {
            color: $white;
          }
        }
      }
    }
    .tabs-content {
      .tabs-panel {
        .tab-accordion-title {
          &.is-active {
            color: $white;
          }
        }
      }
    }
  }

  // tab content border color
  &.ele-border-black {
    .tabs {
      border-color: $black;
    }
    .tabs-content {
      border-color: $black;
      .tab-accordion-title {
        border-color: $black;
      }
    }
  }
  &.ele-border-dark-grey {
    .tabs {
      border-color: $dark-grey;
    }
    .tabs-content {
      border-color: $dark-grey;
      .tab-accordion-title {
        border-color: $dark-grey;
      }
    }
  }
  &.ele-border-light-grey {
    .tabs {
      border-color: $light-grey;
    }
    .tabs-content {
      border-color: $light-grey;
      .tab-accordion-title {
        border-color: $light-grey;
      }
    }
  }
  &.ele-border-white {
    .tabs {
      border-color: $white;
    }
    .tabs-content {
      border-color: $white;
      .tab-accordion-title {
        border-color: $white;
      }
    }
  }
}
