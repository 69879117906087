.quote-container {
  position: relative;
  @include breakpoint(small){
    padding-left: rem-calc(32);
  }
  @include breakpoint(medium){
    padding-left: rem-calc(48);
  }
  @include breakpoint(large){
    padding-left: rem-calc(64);
  }
  .quote-left, .quote-right {
    display: inline-block;
    img {
      @include breakpoint(small){
        width: rem-calc(20);
      }
      @include breakpoint(medium){
        width: rem-calc(30);
      }
      @include breakpoint(large){
        width: rem-calc(40);
      }      
    }
  }
  .quote-left {
    left:0;
    position: absolute;
    top:0;
    @include breakpoint(large){
      top: rem-calc(10);
    } 
  }
  .quote-right {
    vertical-align: text-bottom;
    @include breakpoint(small){
      padding-left: rem-calc(4);
    }
    @include breakpoint(medium){
      padding-left: rem-calc(10);  
    }
    @include breakpoint(large){
      padding-left: rem-calc(16);   
    }
  }
  .quote-content {
    p {
      color: $white;
      display: inline;
      font-weight: 300;
      @include breakpoint(small){
        font-size: rem-calc(24);
        line-height: rem-calc(28);
      }
      @include breakpoint(medium){
        font-size: rem-calc(24);
        line-height: rem-calc(32);
      }
      @include breakpoint(large){
        font-size: rem-calc(40);
        line-height: rem-calc(56);
      }
    }
  }
}
.quote-author {
  font-weight: bold;
  text-align: left;
  @include breakpoint(small){
    font-size: rem-calc(19);
    line-height: rem-calc(19);
    padding-left: rem-calc(32);
    padding-top: rem-calc(16);
  }
  @include breakpoint(medium){
    font-size: rem-calc(19);
    line-height: rem-calc(19);
    padding-left: rem-calc(48);
    padding-top: rem-calc(24);
  }
  @include breakpoint(large){
    font-size: rem-calc(20);
    line-height: rem-calc(24);
    padding-left: rem-calc(64);
    padding-top: rem-calc(32);
  }
}
.quote-fc-white {
  .quote-container {
    .quote-content {
      p {
        color: $white;
      }
    }
  }
  .quote-author {
    color: $white;
  }
}

.quote-small {
  .quote-container {
    @include breakpoint(small){
      padding-left: rem-calc(32);
    }
    @include breakpoint(medium){
      padding-left: rem-calc(32);
    }
    @include breakpoint(large){
      padding-left: rem-calc(48);
    }
    .quote-left, .quote-right {
      display: inline-block;
      img {
        @include breakpoint(small){
          width: rem-calc(20);
        }
        @include breakpoint(medium){
          width: rem-calc(30);
        }
        @include breakpoint(large){
          width: rem-calc(32);
        }      
      }
    }
    .quote-left {
      top: 0;
    }
    .quote-content {
      p {
        color: $white;
        display: inline;
        font-weight: 300;
        @include breakpoint(small){
          font-size: rem-calc(16);
          line-height: rem-calc(24);
        }
        @include breakpoint(medium){
          font-size: rem-calc(20);
          line-height: rem-calc(28);
        }
        @include breakpoint(large){
          font-size: rem-calc(24);
          line-height: rem-calc(32);
        }
      }
    }
  }
  .quote-author {
    font-weight: bold;
    text-align: left;
    @include breakpoint(small){
      font-size: rem-calc(16);
      line-height: rem-calc(16);
      padding-left: rem-calc(32);
    }
    @include breakpoint(medium){
      font-size: rem-calc(18);
      line-height: rem-calc(18);
      padding-left: rem-calc(32);
    }
    @include breakpoint(large){
      font-size: rem-calc(18);
      line-height: rem-calc(22);
      padding-left: rem-calc(48);
    }
  }
}