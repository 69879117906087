.filter-container {
  display: flex;
  justify-content: space-between;  
  width: 100%;
  @include breakpoint(small) {
    flex-wrap: wrap;
  }
  @include breakpoint(medium) {
    flex-wrap: nowrap;    
    margin-left: rem-calc(-16);
    margin-right: rem-calc(-16);
  }
  .filter-item { 
    @include breakpoint(small) {
      margin: rem-calc(16) 0;
      width: 100%;
    }
    @include breakpoint(medium) {
      margin: rem-calc(16);
      width: 33%;  
    }
    label {
      display: block;
      font-size: rem-calc(24);
      font-weight: bold;
      line-height: rem-calc(24);
      margin-bottom: rem-calc(8);
    }
  }
  .filter-item-dropdown {    
    background-color: $black;
    background-image: url('/assets/images/filter-arrow.svg');
    background-position: calc(100% - 1rem) center;
    background-repeat: no-repeat;
    background-size: rem-calc(24); 
    border-color: $black;
    color: $white;    
    cursor: pointer;
    font-weight: bold;
    font-size: rem-calc(20);
    padding: rem-calc(16) rem-calc(56) rem-calc(16) rem-calc(16);
    position: relative;
    -webkit-appearance: none;
  }
}

.job-listing {
  margin: rem-calc(40) 0;
  .job-list-item {
    border: rem-calc(1) solid $black;
    margin-bottom: rem-calc(48);
    position: relative;
    @include breakpoint(small) {
      padding: rem-calc(32) rem-calc(24) rem-calc(112) rem-calc(24);
    }
    @include breakpoint(medium) {
      padding: rem-calc(32) rem-calc(260) rem-calc(32) rem-calc(24);
    }
    p {
      font-size: rem-calc(24);
      line-height: rem-calc(28);
      margin-bottom: 0;
    }
    .job-list-item-dept, .job-list-item-title {  
      margin-bottom: rem-calc(24);
    }
    .job-list-item-location {  
      margin-bottom: rem-calc(10);
    }
    .job-list-item-date {  
      font-size: rem-calc(20);
      font-weight: bold;
      line-height: rem-calc(24);
      @include breakpoint(small) {
        margin-top: rem-calc(16);
      }
      @include breakpoint(medium) {
        margin-top: 0;
        max-width: rem-calc(200);    
        position: absolute;
        right: rem-calc(24);
        text-align: right;
        top: rem-calc(32);
      }
    }
    .btn {
      border-color: $black;
      background-color: $black;
      color: $white;
      bottom: rem-calc(32);
      font-size: rem-calc(24);
      font-weight: bold;
      letter-spacing: rem-calc(-0.25);
      line-height: rem-calc(24);
      padding: rem-calc(14) rem-calc(52) rem-calc(14) rem-calc(24);
      position: absolute;
      right: rem-calc(24);
      .arrow {
        background-color: $white;
      }
    }
  }
}

.job-list-pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  li {
    margin: rem-calc(8);
    a {
      padding: 0;
    }
  }
}